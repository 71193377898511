/* Pagination */
.pagination {
  display: flex;
  gap: 5px;
}
.page-item {
  border: solid 1px #10b981;
  border-radius: 5px;
  padding: 15px;
}

.page-item.active {
  background-color: #10b981;
}

.page-item.active .page-link {
  color: #fefefe;
}

.page-link {
  color: #10b981;
}

.page-item:hover {
  background-color: #fa9d2372;
}

.page-item:hover .page-link {
  color: bisque;
}

.table {
  overflow-x: auto !important;
}
